<template>
  <div>
      <h1 class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white" style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1">
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="ni ni-single-02 "></i> User
      </h6>
    </h1>
    
    <base-header class="pb-6">
        <b-row>
          <b-col xl="12" md="12">
            <b-card style="position: relative;overflow: hidden;">

              <h3>Cari Berdasarkan</h3>

              <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">
                <!-- status pendaftaran -->
                <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                  <label><small>Status Pendaftaran</small></label>
                  <el-select class="select-primary pagination-select" v-model="filterStatusPendaftaran"  placeholder="Status Pendaftaran">
                  <el-option
                      class="select-primary"
                      v-for="item in selectOptionStatus"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                  >
                  </el-option>
                  </el-select>
                </div>

                <!-- Tanggal -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;">
                  <label><small>Tanggal</small></label>
                  <base-input name="Tanggal">
                    <flat-picker
                      placeholder="Filter Tanggal"
                      :config="provideSelect.flatPickerConfig"
                      class="form-control datepicker"
                      v-model="userInput.tanggal"
                      style="background:white;"
                    />
                  </base-input>
                </div>


                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Jenis Industri</small></label>
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType">
                      <el-option value="">Semua Jenis Industri</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Provinsi</small></label>
                  <base-input name="Provinsi" >
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                        label="Semua Provinsi"
                        :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota" >
                      <el-select
                        v-model="form.umum.compCity"
                        >
                        <el-option
                          v-for="option in provideSelect.kabupatenKota"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Kabupaten kota -->
                <!-- <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota">
                    <select
                      class="form-control"
                      v-model="form.umum.compCity"
                    >
                      <option
                        v-for="(option, idx) in provideSelect.kabupatenKota"
                        :key="idx"
                        :value="option.id"
                      >
                        {{ option.name }}
                      </option>
                    </select>
                  </base-input>
                </div> -->

                <!-- Nama Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" >
                  <label><small>Nama Industri</small></label>
                  <base-input name="Nama Industri" >
                      <el-select
                        v-model="userInput.companyName"
                        @change="getMyCompliencePoint"
                        :disabled="log_as !== 'admin'"
                        >
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in provideSelect.companyName"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                

               

                <!-- Titik Penaatan -->
                <div style="display: flex;flex-direction: column;width: 180px;">
                  <label><small>Titik Penaatan</small></label>
                  <base-input>
                      <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in listDataCompliencePoints"
                          :key="option.id"
                          :label="option.nama_titik_penaatan"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>
                
                
              </div>

              <base-button size="md" type="primary" @click="getBaseOnStatus"> Tampilkan </base-button>
              <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -100px;z-index: 50;opacity: 0.1;">
            </b-card>
          </b-col>
        </b-row>
      </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            <b-row>
              <b-col lg="6" cols="7">
                <!-- <h6 class="surtitle">Overview</h6>
                <h5 class="h3 mb-0">Total sales</h5> -->
              </b-col>
              <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
                <button
                  class="btn text-white btn-sm"
                  style="background-color: #0a7641;"
                  @click="downloadExcelNow"
                >
                  <i class="fa fa-solid fa-file-excel mr-2" style="font-size: 20px;"></i>
                    Unduh Excel
                </button>
              </b-col>
            </b-row>
            </template>

            <section id="mytable">
              <el-table
                :data="listData"
                row-key="id"
                header-row-class-name="thead-light"
              >

                <el-table-column label="No" type="index" width="70" />

                <el-table-column min-width="180px"  label="Email">
                  <div slot-scope="action">
                    {{ action.row.email }}
                  </div>
                </el-table-column>

                <el-table-column min-width="180px"  label="Nama">
                  <div slot-scope="action">
                    {{ action.row.name }}
                  </div>
                </el-table-column>

                <el-table-column min-width="180px"  label="Nama Perusahaan">
                  <div slot-scope="action">
                    {{ action.row.company_id }}
                  </div>
                </el-table-column>

                <el-table-column min-width="100px"  label="Role">
                  <div slot-scope="action">
                    {{ action.row.role_name }}
                  </div>
                </el-table-column>


                <el-table-column min-width="180px"  label="Alamat">
                  <div slot-scope="action">
                    {{ action.row.address }}
                  </div>
                </el-table-column>

                <el-table-column min-width="150px"  label="Telp">
                  <div slot-scope="action">
                    {{ action.row.phone }}
                  </div>
                </el-table-column>


                <el-table-column min-width="250px"  label="AKSI">
                  <div slot-scope="action">
                      <base-button
                        @click="handleEdit(action.row)"
                        type="warning"
                        size="sm"
                        icon
                        >
                        UBAH
                      </base-button>
                      <base-button
                        @click="handleDelete(action.row)"
                        type="danger"
                        size="sm"
                        icon
                        >
                        HAPUS
                      </base-button>
                  </div>
                </el-table-column>
               </el-table>

            <div class="py-3">

                  <el-select
                    class="select-primary pagination-select mr-3"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    @change="changeSizePage()"
                    >
                    <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                  </el-select>

                  <small class="card-category">
                  Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                  </small>
              </div>

              <div style="display: flex;justify-content: flex-end;">
                <base-pagination
                    class="pagination-no-border"
                    :current="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    @change="paginationChanged($event)"
                >
                </base-pagination>
              </div>
            </section>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

     <!-- EDIT USER -->
     <Modal
      :show="editModal"
      modalContentClasses="mdl-content"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="editModal = false"
      size="xl"
    >
      <template v-slot:header>
        <div class="modal-title">
          <h4 class="mdl-title">Ubah Pengguna</h4>
        </div>
      </template>
      <div class="px-4 border-top pt-4">

        <div class="form-group row">
          <label class="col-form-label form-control-label">Alamat</label>
          <input type="text" class="form-control" v-model="alamat" />
        </div>

        <div class="form-group row mt--3">
          <label class="col-form-label form-control-label">Telepon</label>
          <input type="text" class="form-control" v-model="telepon" />
        </div>


        <div class="form-group row mt--3">
          <label class="col-form-label form-control-label">Role Pengguna</label>

          <div class="col-lg-12 m-0 p-0">
            <base-input name="Role">
              <el-select v-model="role">
                <el-option
                  v-for="(item, index) in listRole"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="form-group row mt--3">
          <label class="col-form-label form-control-label">Password</label>
          <input type="text" class="form-control" v-model="password" />
        </div>
       


        <div class="form-group row">
          <button class="btn btn-primary" @click="doEdit()">UBAH</button>
          <button class="btn btn-danger" @click="editModal = false">BATAL</button>
        </div>
      </div>
    </Modal>

       <!-- Loading Screen -->
       <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative;">
                <img src="img/brand/img-logo-color.png" alt="">
                <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -200px;opacity: 0.2;">
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Modal>


    <!-- Download Modal Excel -->
    <Modal
      :show="showDownloadModalCSV"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      @close="showDownloadModalCSV = false"
      size="xl"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4" style="height: 500px;overflow: scroll;">
            <div>
              <div>

                <div style="padding:10px;overflow-x: scroll;" >
                  <table class="table mt-3" ref="exportable_table">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-white">No</th>
                      <th class="text-white">Email</th>
                      <th class="text-white">Nama Industri</th>
                      <th class="text-white">Jenis Industri</th>
                      <th class="text-white">Kabupaten / Kota</th>
                      <th class="text-white">Provinsi</th>
                      <th class="text-white">Alamat</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, idx) in listDataDownload">
                      <td>{{ idx + 1 }}</td>
                      <td>{{ item.email }}</td>
                      <td>Nama Industri</td>
                      <td>Jenis Industri</td>
                      <td>Kabupaten / Kota</td>
                      <td>Provinsi</td>
                      <td>Alamat</td>
                    </tr>
                  </tbody>
                </table>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="row">
          <div class="col text-right">
            <base-button
              class="bg-primary border-0"
              @click="ExportExcel('xlsx')"
            >
              Unduh Excel
            </base-button>
            <base-button
              class="bg-danger border-0"
              @click="showDownloadModalCSV = false"
            >
              Tutup
            </base-button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import API from '../../../api/base_url'
import { Select, Option, Table, TableColumn } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Modal from "@/components/Modal.vue";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import moment from 'moment'



export default {
components: {
  BaseHeader,
  DataSparingChart,
  flatPicker,
  Modal,
  [Select.name]: Select,
  [Option.name]: Option,
  [Table.name]: Table,
  [TableColumn.name]: TableColumn,
},
mounted() {
  this.getData()
  this.getRole()
  // new
  this.log_as = localStorage.getItem("as");
  this.getCompliencePoint()
  this.getCompanyType()
  this.getCompanyName()
  this.getSelectFormValue()
  this.getCompanyProfile()
},
data() {
  return {
    log_as: "",
      pdfName:"",
      listDataDownload: [],
      listDataCompliencePoints: [],
      filterStatusPendaftaran: "",
      selectOptionStatus: [
        {
          val:"",
          name:"Semua"
        },
        {
          val:0,
          name:"Menunggu"
        },
        {
          val:1,
          name:"Disetujui"
        },
        {
          val:2,
          name:"Ditolak"
        },
      ],
      userInput: {
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      provinsi:[],
      kabupatenKota:[],
      regency:[]
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,
          plugins: [
            ShortcutButtonsPlugin({
              button: [
                {
                  label: "Data Hari ini",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Minggu",
                  attributes:{
                    class:"btn w-75 btn-dark mb-2 btn-sm"
                  }
                },
                {
                  label: "Data 1 Bulan",
                  attributes:{
                    class:"btn w-75 btn-dark mb-5 btn-sm"
                  }
                },
                {
                  label: "Reset Pencarian Tanggal",
                  attributes:{
                    class:"btn w-100 btn-danger btn-sm"
                  }
                },
              ],
              onClick:(index,fp)=>{
                fp.clear();

                if(index == 0){
                const startDate = moment().startOf('day');
                  const endDate = moment(startDate).endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                  
                }else if(index == 1){
                  const startDate = moment().startOf('day');
                  const endDate = moment(startDate).add(1, 'week').endOf('day');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);

                 
                }else if(index == 2){
                  const startDate = moment().startOf('month');
                  const endDate = moment().endOf('month');
                  fp.setDate([startDate.toDate(), endDate.toDate()]);
                }else{
                  fp.clear();
                }
              }
            }),
          ], // Gunakan plugin di sini

          defaultHour: 0,
          mode: "range",
        },
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false,
      showDownloadModal:false,
      showDownloadModalCSV:false,
    listDataDownload: [],
    listRole: [],
    idWillUpdate: "",
    alamat:"",
    telepon:"",
    role:"",
    password:"",
    editModal: false,
    pagination: {
    perPage: 10,
    currentPage: 1,
    perPageOptions: [5, 10, 20, 50],
    total: 1
    },
    listData: [],
  };
},
methods: {
  getRole() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      API.get(`roles` , {headers})
        .then(({data}) => {
          if (data.data == null) {
            this.listRole = []
          } else {
            this.listRole = data.data
          }
        })
        .catch((err) => {
          console.log(err);
      })
  },
  changeSizePage() {
    this.getData()
  },
  formatDate(timestamp) {
      const momentObj = moment.unix(timestamp);
      const humanDate = momentObj.format('DD-MM-YYYY - HH:mm:ss');
      return humanDate;
    },
    getDataDownload() {
      this.isLoading = true

      let tmpDate = this.userInput.tanggal.split("to")

      let startDate = tmpDate[0]
      let endDate = tmpDate[1]


      if (endDate == undefined) {
        endDate = startDate
      } else if (endDate) {
        endDate.trim()
        startDate.trim()
      }


      let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      const queryParams = {
        status:this.filterStatusPendaftaran,
        is_export: true,
        start_date: startDate,
        end_date:endDate,
        province_id:this.form.umum.compProvince,
        regency_id:this.form.umum.compCity,
        company_id:this.userInput.companyName,
        company_type_id:this.userInput.companyType,
        sparing_compliance_point_id:this.userInput.titikPenaatan
      };

      const params = Object.fromEntries(
          Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );


        API.get(`users` , { params, headers})
          .then(({data}) => {
            if (data.data == null) {
                this.listDataDownload = []
                this.isLoading = false
              } else {
                this.listDataDownload = data.data
                this.pagination.total = data.meta.info.count
                setTimeout(() => {
                  this.isLoading = false
                }, 200);
              }
          })
          .catch((err) => {
            this.isLoading = false
            console.log(err);
          })
    },
    
    downloadExcelNow() {
      this.getDataDownload()
      this.showDownloadModalCSV = true
    },
    getCompanyProfile() {
      let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`companies/me` , {headers})
          .then(({data}) => {
            if (data.data == null) {

            } else {
              this.companyMe = data.data

              if (this.log_as !== 'admin') {
                this.userInput.companyName = data.data.company.id
                this.pdfName = data.data.company.name
              }
            }
           
          })
          .catch((err) => {
            console.log(err);
        })
    },
    getCompanyType() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('companies/types?page=1&page_size=100', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyType = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getCompanyName() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get('companies?page=1&page_size=1000', {headers})
      .then(({data:content}) => {
        this.provideSelect.companyName = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getSelectFormValue() {
    API.get(`region/provinces?page=1&page_size=100`)
      .then(({ data: content }) => {
        this.provideSelect.provinsi = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
    },
    getCityDepentOnProvince(data) {
      this.form.umum.compCity = []
      this.provideSelect.kabupatenKota = [];
      API.get(`region/provinces/regencies?province_id=${data}`)
      // API.get(`region/regencies/province/${data}`)
        .then(({ data: content }) => {
          this.provideSelect.kabupatenKota = content.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`users` , {headers})
      .then(({data:content}) => {
        this.listDataCompliencePoints = content.data
      })
      .catch((err) => {
        console.log(err);
      })
    },
    getMyCompliencePoint() {
       let headers = {
         Authorization: `Bearer ${localStorage.getItem('token')}`
       }

       API.get(`users` , {headers})
       .then(({data:content}) => {
         this.listDataCompliencePoints = content.data
       })
       .catch((err) => {
         console.log(err);
       })
    },
    getBaseOnStatus() {
    
    this.isLoading = true

    let tmpDate = this.userInput.tanggal.split("to")

    let startDate = tmpDate[0]
    let endDate = tmpDate[1]


    if (endDate == undefined) {
      endDate = startDate
    } else if (endDate) {
      endDate.trim()
      startDate.trim()
    }


    let headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    const queryParams = {
      page:this.pagination.currentPage,
      page_size:this.pagination.perPage,
      status:this.filterStatusPendaftaran,
      start_date: startDate,
      end_date:endDate,
      province_id:this.form.umum.compProvince,
      regency_id:this.form.umum.compCity,
      company_id:this.userInput.companyName,
      company_type_id:this.userInput.companyType,
      sparing_compliance_point_id:this.userInput.titikPenaatan
    };

     const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
      );


    API.get(`users` , { params, headers})
      .then(({data}) => {
        if (data.data == null) {
            this.listData = []
            this.isLoading = false
          } else {
            this.listData = data.data
            this.pagination.total = data.meta.info.count
            setTimeout(() => {
              this.isLoading = false
            }, 200);
          }
      })
      .catch((err) => {
        this.isLoading = false
        console.log(err);
      })
    },
    ExportExcel(type, fn, dl) {
        var elt = this.$refs.exportable_table;
        var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
        Swal.fire('Data di Unduh','','success')
        this.showDownloadModalCSV = false
        return dl ?
            XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
            XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
    },
    reportTableState() {
        return []
    },
  paginationChanged(page) {
    this.pagination.currentPage = page;
    this.getData()
  },
  getData() {
    let headers = {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }

        API.get(`users` , {headers})
          .then(({data}) => {
            if (data.data == null) {
              this.listData = []
            } else {
              this.listData = data.data
              this.pagination.total = data.meta.info.count
            }
          })
          .catch((err) => {
            Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
            this.isLoading = false
        })
  },
  handleEdit(row) {
    this.editModal = true

    this.alamat = row.address,
    this.telepon = row.phone,
    this.role = row.role_id
    this.idWillUpdate = row.id
  },
  handleDelete(row) {

  },
  doEdit() {

  }
},
};
</script>

  